import React from 'react'
import Fade from 'react-reveal/Fade'
import Section from '../../../common/SectionComponent/Section'
import IndustryPlates from '../../Industry/IndustryPlatesSection/IndustryPlates'

const OneBusinessIndustry = ({ heading, data }) => {
  const { title, description } = heading
  const hoverPlateData = {
    buttonText: 'See more',
    link: '/industries/',
    title: 'Discover more industries',
  }
  const plates = data
    .filter((e) => e.industry)
    .map((e) => ({
      image:
        e.industry.industry.image.localFile.childImageSharp.gatsbyImageData
          .images.fallback.src,
      title: e.industry.industry.title,
      description: '',
      link: `/portfolio/?${e.button?.filterBy}=${e.industry.slug}`,
      buttonText: e.button.text,
    }))
  return (
    <Section title={title}>
      <Fade bottom>
        <p className="text-color__gray--on-dark">{description}</p>
      </Fade>
      <IndustryPlates
        onDark
        platesData={plates}
        maxPlates={5}
        hoverPlateData={hoverPlateData}
      />
    </Section>
  )
}

export default OneBusinessIndustry
