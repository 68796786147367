import React from 'react'

import { graphql } from 'gatsby'
import MainLayout from '../../layouts/MainLayout'
import OneBusinessHeader from '../../components/Pages/Businesses/OneBusinessHeader/OneBusinessHeader'
import OneBusinessStages from '../../components/Pages/Businesses/OneBusinessStages/OneBusinessStages'
import OneBusinessSolutions from '../../components/Pages/Businesses/OneBusinessSolutions/OneBusinessSolutions'
import OneBusinessIndustry from '../../components/Pages/Businesses/OneBusinessIndustry/OneBusinessIndustry'
import EnterprisesSpecial from '../../components/Pages/Businesses/EnterprisesSpecial/EnterprisesSpecial'
import OneServicePortfolio from '../../components/Pages/OneService/OneServicePortfolio/OneServicePortfolio'
import MainClients from '../../components/Pages/main/MainClients/MainClients'
import OneServiceBlog from '../../components/Pages/OneService/OneServiceBlog/OneServiceBlog'

const BusinessEnterprises = ({ data, location }) => {
  const { page_enterprises: pageData, metaTags } = data.wpPage
  const {
    enterprisesSolutionHeading,
    enterprisesSolutionsItems,
    enterprisesIndustryHeading,
    enterprisesIndustry,
    enterprisesSpecialHeading,
    enterprisesSpecialsItem,
    enterprisesPortfolioButton,
    enterprisesPortfolioHeading,
    enterprisesPortfolioItems,
    enterprisesClientsSection,
    blogPosts,
    blogHeader,
  } = pageData
  return (
    <MainLayout seoData={metaTags} location={location}>
      <OneBusinessHeader
        isEnterprise
        data={pageData}
        button={pageData.enterprisesHeaderButton}
      />
      <OneBusinessStages data={pageData.stagesSection} />
      <EnterprisesSpecial
        data={enterprisesSpecialsItem}
        heading={enterprisesSpecialHeading}
      />
      <OneBusinessSolutions
        headingData={enterprisesSolutionHeading}
        items={enterprisesSolutionsItems}
      />
      <OneBusinessIndustry
        heading={enterprisesIndustryHeading}
        data={enterprisesIndustry}
      />
      <OneServicePortfolio
        title={enterprisesPortfolioHeading.title}
        description={enterprisesPortfolioHeading.description}
        buttonData={enterprisesPortfolioButton}
        portfolioItems={enterprisesPortfolioItems}
      />
      <MainClients
        clientItems={enterprisesClientsSection.clientItems}
        heading={enterprisesClientsSection.clientsHeader}
      />
      <OneServiceBlog
        isLight
        isGray
        data={blogPosts}
        heading={blogHeader}
        newClass={{ backgroundColor: 'red' }}
      />
    </MainLayout>
  )
}

export const query = graphql`
  {
    wpPage(databaseId: { eq: 64 }) {
      metaTags {
        seoTitle
        seoDescription
        seoKeywords
        ogImage {
          altText
          sourceUrl
          localFile {
            childImageSharp {
              resize(width: 1024, height: 512) {
                src
              }
            }
          }
          title
        }
      }
      page_enterprises {
        headerImage {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        headerText
        stagesSection {
          stagesHeader {
            description
            title
          }
          stagesBusiness {
            ... on WpBusiness {
              business {
                stages {
                  description
                  title
                }
              }
            }
          }
        }
        enterprisesHeaderButton {
          addQuery
          text
          query
          useCustomUrl
          urlCustom
          url {
            ... on WpPost {
              uri
            }
            ... on WpPage {
              uri
            }
          }
        }
        enterprisesSolutionHeading {
          description
          title
        }
        enterprisesSolutionsItems {
          description
          title
        }
        enterprisesIndustryHeading {
          title
          description
        }
        enterprisesIndustry {
          button {
            text
            filterBy
            industry {
              slug
            }
          }
          industry {
            ... on WpIndustry {
              industry {
                title
                image {
                  localFile {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
              }
              slug
            }
          }
        }
        enterprisesPortfolioButton {
          addQuery
          text
          query
          useCustomUrl
          urlCustom
          url {
            ... on WpPost {
              uri
            }
            ... on WpPage {
              uri
            }
          }
        }
        enterprisesSpecialHeading {
          description
          title
        }
        enterprisesSpecialsItem {
          description
          title
          options {
            text
          }
        }
        enterprisesPortfolioHeading {
          description
          title
        }
        enterprisesPortfolioItems {
          ... on WpPortfolio_item {
            uri
            portfolioItem {
              title
              buttonText
              embeddedHtml
              type
              image {
                sourceUrl
              }
              plateVideo {
                localFile {
                  url
                }
              }
            }
          }
          ... on WpPortfolio_item {
            portfolioItem {
              caseStudySections {
                ... on WpPortfolio_item_Portfolioitem_CaseStudySections_Client {
                  playbackButton {
                    text
                    embeddedHtml
                  }
                }
              }
            }
          }
        }
        enterprisesPortfolioItems {
          ... on WpPortfolio_item {
            uri
            portfolioItem {
              title
              buttonText
              embeddedHtml
              type
              image {
                sourceUrl
              }
            }
          }
          ... on WpPortfolio_item {
            portfolioItem {
              caseStudySections {
                ... on WpPortfolio_item_Portfolioitem_CaseStudySections_Client {
                  playbackButton {
                    text
                    embeddedHtml
                  }
                }
              }
            }
          }
        }
        enterprisesClientsSection {
          clientItems {
            ... on WpClient {
              client {
                title
                name
                description
                photo {
                  localFile {
                    url
                  }
                }
                country {
                  localFile {
                    childImageSharp {
                      resize(width: 24, height: 24) {
                        src
                      }
                    }
                  }
                  altText
                  title
                }
              }
            }
          }
          clientsHeader {
            description
            title
          }
        }
        blogHeader {
          description
          fieldGroupName
          title
        }
        blogPosts {
          ... on WpPost {
            uri
            link
            title
            blogPost {
              blogPrewiev
              image {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
              minutesToRead
              tags {
                name
              }
            }
            blogAuthors {
              nodes {
                name
                blogAuthor {
                  photo {
                    altText
                    localFile {
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default BusinessEnterprises
