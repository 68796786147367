import Fade from 'react-reveal/Fade'
import React, { useState } from 'react'
import className from 'classnames'
import Section from '../../../common/SectionComponent/Section'
import * as style from '../StartupsSpecials/startups-specials.module.scss'
import * as styleEnterprises from './enterprice-specials.module.scss'
import bgImage from '../../../../../static/images/enterprises-bg.png'

const EnterprisesSpecial = ({ data, heading }) => {
  const { title, description } = heading

  const [active, setActive] = useState(0)
  const nextSlide = () => {
    const next = active === data.length - 1 ? 0 : active + 1
    setActive(next)
  }
  const prevSlide = () => {
    const prev = active === 0 ? data.length - 1 : active - 1
    setActive(prev)
  }
  return (
    <div
      className={style.wrap}
      style={{ backgroundImage: `url('${bgImage}')` }}
    >
      <Section newClass={style.background} title={title}>
        <Fade bottom>
          <p className="text-color__gray--on-dark">{description}</p>
        </Fade>
        <div className={styleEnterprises.items}>
          {data.map((e, i) => (
            <div
              className={className(
                i === active && style.activeItem,
                style.item
              )}
            >
              <div className={style.buttonWrap}>
                <button
                  aria-label="Prevision slide"
                  type="button"
                  onClick={prevSlide}
                  onKeyDown={prevSlide}
                  className={style.button}
                />
                <h3 className={styleEnterprises.heading}>{e.title}</h3>
                <button
                  aria-label="Next slide"
                  type="button"
                  onClick={nextSlide}
                  onKeyDown={nextSlide}
                  className={style.button}
                />
              </div>

              <p
                className={className(
                  'text-color__gray--on-dark',
                  styleEnterprises.text
                )}
              >
                {e.description}
              </p>
              {e.options && (
                <ul className={styleEnterprises.list}>
                  {e.options.map((option) => (
                    <li
                      className={className(
                        styleEnterprises.listItem,
                        'text-color__gray--on-dark'
                      )}
                    >
                      {option.text}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          ))}
        </div>
      </Section>
    </div>
  )
}

export default EnterprisesSpecial
